<template>
  <div class="legal-advice">
    <div class='container'>
      <h1>Legal Advice</h1>
      <h5 />
    </div>
    <div class='content container'>
      <div v-if="submitted">
        Thank you for contacting us, we will be in touch shortly.
      </div>
      <form v-else v-on:submit.prevent="submit">
        <div class='input'>
          <label>Your Name</label>
          <input name='name' type='text' v-model="form.name" />
        </div>
        <div class='input'>
          <label>Your Email</label>
          <input name='email' type='email' v-model="form.email" />
        </div>
        <div class='input'>
          <label>Zip Code</label>
          <input name='zip_code' type='text' v-model="form.zip_code" />
        </div>     
        <div class='input'>
          <label>Phone Number</label>
          <input name='phone_number' type='tel' v-model="form.phone_number" />
        </div>
        <div class='input'>
          <label>How would you like to be contacted?</label>
          <div>
            <input type='checkbox' name='by_email' v-model="form.by_email"> Email
            <input type='checkbox' name='by_phone' v-model="form.by_phone"> Phone
          </div>
        </div>
        <div class='input'>
          <label>How can we help you?</label>
          <textarea rows='10' v-model="form.message" name='message'/>
        </div>
        <div class='text-justify'>
          The use of the Internet, this website, or this form for communication with The Law 
          Offices of Robin S. Stith does not establish an attorney-client relationship. Confidential 
          or time-sensitive information should not be sent through this form.
        </div>
        <div>
          <input type='checkbox' name='tos' v-model='form.tos'> <strong>I have read and I understand the above disclaimer.</strong>
        </div>
        <div class='text-justify'>
          <button type='submit' class='btn' :disabled="!form.tos">
            Send
          </button>
        </div>
      </form>
      <div>
        <div>
          <Contact />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
import emailjs from 'emailjs-com';

export default {
  name: 'LegalAdvice',
  components: { 
    Contact
  },
  data() {
    return {
      submitted: false, 
      form: {
        name: null,
        email: null,
        zip_code: null,
        phone_number: null,
        by_email: false,
        by_phone: false,
        message: null,
        tos: false
      }
    }
  },
  methods: {
    submit(e) {
      emailjs.sendForm('contact-form', 'legal-advice', e.target, 'user_gGy04PP68hpyKactjhRPb', this.form)
        .then(() => {
          this.submitted = true
          this.form = {
            name: null,
            email: null,
            zip_code: null,
            phone_number: null,
            by_email: false,
            by_phone: false,
            message: null,
            tos: false
          }
        }, error => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style scoped lang='scss'>
.legal-advice {
  padding-bottom: 50px;
  h1 {
    font-family: "Merriweather", serif;
    margin-bottom: 10px;
  }

  h5 {
    margin: 0px;
    padding-bottom: 10px;
    font-weight: 300;
    color: #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }

  form {
    margin-right: 20px;
    &>div {
      margin-bottom: 10px;
    }

    div.input {
      display: grid;
      grid-template-columns: 1fr 1fr;

      margin-bottom: 10px;

      label {
        text-align: right;
        padding-right: 10px;
        font-weight: bold;
      }
      input {

      }
    }
  }

  .content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    

    div:nth-child(2) {
      div {
        border-radius: 5px;
        background-color: #2F3439;
        padding: 15px;
      }
    }
  }
}

</style>
